(function(){"use strict";class f{constructor(){this.data={}}set(t,e){this.data[t]=e}get(t){return this.data[t]}}const d={Booster:class{constructor(t){this.options=t}log(t,e){const s=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,r=[`%c[${this.options.title}] ${t}. Link to documentation ${this.options.documentationLink}`,s];e?(console.group(...r),Array.isArray(e)?console.log(...e):console.log(e),console.groupEnd()):console.log(...r)}validate(t,e,s){if(!t.validate)return!0;if(typeof t.validate=="function"){if(!t.validate(s))return this.log(`Invalid value "${s}" for attribute "${e}"`),!1}else if(!t.validate.includes(s))return this.log(`Invalid value "${s}" for attribute "${e}"`,[`%cPossible values:%c
`+t.validate.map(r=>`• ${r}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(t){const e=new f;for(const s in this.options.attributes){const r=this.options.attributes[s],n=t.getAttribute(s);if(!n){e.set(s,r.defaultValue);continue}if(!this.validate(r,s,n))continue;let i=n;r.parse&&(i=r.parse(n)??r.defaultValue),e.set(s,i)}this.options.apply.call(this,t,e)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(e=>this.parse(e))}},parse:{stringToBoolean:o=>o!=="false"},validation:{isBoolean:o=>/^(true|false)$/.test(o),isNumber:o=>!isNaN(Number(o))}};var l=(o=>(o.Left="left",o.Right="right",o))(l||{});const g=new d.Booster({name:"fb-slider",attributes:{},apply(o){const t=o.querySelector(".w-slider-arrow-left"),e=o.querySelector(".w-slider-arrow-right");if(!t||!e)return;const s=o.querySelectorAll("[fb-slider-arrow]");if(!s.length)return this.log("Required attribute is missing");const r=[],n=[];for(const i of Array.from(s)){const u=i.getAttribute("fb-slider-arrow"),a=u;Object.values(l).includes(a)?(a==="left"&&r.push(i),a==="right"&&n.push(i)):this.log(`Invalid value "${u}" for attribute "fb-slider-arrow"`,[`%cPossible values:%c
`+Object.values(l).map(h=>`• ${h}`).join(`
`),"font-weight: 700;","font-weight: initial;"])}r.length&&(t.style.display="none",r.forEach(i=>i.addEventListener("click",()=>t.click()))),n.length&&(e.style.display="none",n.forEach(i=>i.addEventListener("click",()=>e.click())))},title:"Webflow Custom Slider Arrows Booster",documentationLink:"https://www.flowbase.co/booster/webflow-slider-arrows"}),c=()=>g.init();document.readyState==="complete"?c():window.addEventListener("load",c)})();
